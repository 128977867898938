<template>
    <div class="dashboard-wrapper">
        <div class="home-title">
            <div>
                <p>Hello {{ $store.state.userInfo.name }}</p>
                <span>歡迎使用磊山業務管理系統 BMS，</span>
                <span>祝福您擁有美好的一天！</span>
            </div>
            <Button
                buttonWord="BMS系統使用教學"
                buttonStyle="dashboard"
                @click="openBmsManualUrl()"
            />
        </div>
        <TagMenu :options="tagOption" v-model="tagNow" class="home-tag" />
        <!-- 重要通知↓ -->
        <DashboardNotification
            v-show="isTagShow('notification')"
            class="notification"
        />
        <!-- 重要通知↑ -->

        <!-- 近期生日/保險年齡增歲區塊↓ -->
        <DashboardCustomerAge
            v-show="isTagShow('customerAge')"
            class="customer-age"
        />
        <!-- 近期生日/保險年齡增歲區塊↑ -->

        <!-- 重要公告↓ -->
        <DashboardAnnouncement
            v-show="isTagShow('notification')"
            class="dashboard-container item4"
        />
        <!-- 重要公告↑ -->

        <!-- 競賽業績↓ -->
        <DashboardCompetition v-show="isTagShow('performance')" />
        <!-- 競賽業績↑ -->

        <!-- 業績圖表↓ -->
        <DashboardPerformance
            v-show="isTagShow('performance')"
            class="performance"
        />
        <!-- 業績圖表↑ -->

        <!-- 組織人力↓ -->
        <DashboardOrganizationManpower
            v-show="isTagShow('organizationManpower')"
            class="organize"
        />
        <!-- 組織人力↑ -->

        <!-- 熱門/停售商品區塊↓ -->
        <DashboardProducts v-show="isTagShow('products')" class="products" />
        <!-- 熱門/停售商品區塊↑ -->
    </div>
</template>

<script>
import Button from '@/components/Button.vue'
import TagMenu from '@/components/TagMenu.vue'
import DashboardNotification from '@/containers/home/DashboardNotification.vue'
import DashboardCustomerAge from '@/containers/home/DashboardCustomerAge.vue'
import DashboardCompetition from '../containers/home/DashboardCompetition.vue'
import DashboardPerformance from '@/containers/home/DashboardPerformance.vue'
import DashboardAnnouncement from '@/containers/home/DashboardAnnouncement.vue'
import DashboardProducts from '@/containers/home/DashboardProducts.vue'
import DashboardOrganizationManpower from '@/containers/home/DashboardOrganizationManpower.vue'
// import _ from 'lodash'

export default {
    name: 'Home',
    components: {
        Button,
        TagMenu,
        DashboardNotification,
        DashboardCustomerAge,
        DashboardCompetition,
        DashboardPerformance,
        DashboardAnnouncement,
        DashboardProducts,
        DashboardOrganizationManpower
    },
    methods: {
        openBmsManualUrl: function () {
            this.$setGaEvent(`targetBmsManualUrl`, 'click-Link')
            window.open(
                'https://km.leishan.app/?type=all&keyword=BMS%E6%A5%AD%E5%8B%99%E7%AE%A1%E7%90%86%E7%B3%BB%E7%B5%B1%E7%AF%87'
            )
        },
        updateDeviceWidth: function () {
            this.deviceWidth = window.innerWidth
        },
        isTagShow: function (tag) {
            return this.deviceWidth > 576 || this.tagNow === tag
        }
    },
    computed: {},
    watch: {
        tagNow: function (newVal) {
            if (this.$isMobile()) {
                this.$setGaEvent(`${newVal}`, 'toggle-menu')
            }
        }
    },
    data() {
        return {
            tagOption: [
                { label: '重要通知', value: 'notification' },
                { label: '生日', value: 'customerAge' },
                { label: '業績', value: 'performance' },
                { label: '組織人力', value: 'organizationManpower' },
                { label: '商品資訊', value: 'products' }
            ],
            tagNow: 'notification',
            deviceWidth: 0
        }
    },
    mounted() {
        this.$showLoading()
        if (
            !this.$store.state.lastUpdateHomeApiTime ||
            this.$shouldUpdateHomeApi()
        )
            this.$store.commit('set', {
                lastUpdateHomeApiTime: new Date()
            })
        this.$hideLoading()

        this.updateDeviceWidth()
        window.addEventListener('resize', this.updateDeviceWidth)
        this.$store.commit('set', { lastPagePath: this.$route.path })
    },
    unmounted() {
        window.removeEventListener('resize', this.updateDeviceWidth)
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: 0;
}

.dashboard-wrapper {
    display: grid;
    grid-template-columns: minmax(560px, 728px) minmax(388px, 438px);
    grid-template-rows: 58px 183px 235px 252px 271px 459px;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    @media (min-width: 1018px) and (max-width: 1280px) {
        grid-template-columns: minmax(560px, fit-content) minmax(388px, auto);
    }
    @media screen and (max-width: 1017px) {
        display: block;
        > div:not(.menu-container),
        section {
            margin-bottom: 10px;
        }
    }
}

// grid item↓

//item-1
.home-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    p {
        font-size: 20px;
        color: $sixth-black;
        font-weight: bold;
        margin-bottom: 5px;
    }
    span {
        display: inline;
        font-size: 14px;
        color: $placeholder-black;
        @media screen and (max-width: 400px) {
            display: block;
        }
    }
    :deep(.button) {
        margin-top: 5px;
    }
    :deep(.button-word) {
        font-size: 12px;
    }
}
//item-2
.notification {
    grid-column: 1 / 1;
    grid-row: 2/ 3;
}
//item-3
.customer-age {
    grid-column: 2 / 2;
    grid-row: 1 / 3;
}
//item-4
.item-4 {
    background-color: $primary-white-disabled;
    grid-column: 1 / 1;
    grid-row: 3 / 3;
}
//item-5
.performance {
    grid-column: 2 / 2;
    grid-row: 3/ 6;
}
//item-6
.item-6 {
    grid-column: 1 / 1;
    grid-row: 4/ 4;
}
//item-7
.organize {
    grid-column: 1 / 2;
    grid-row: 5/ 5;
}
//item-8
.products {
    grid-column: 1 / 3;
    grid-row: 6/ 6;
}

// grid item↑

.scroll-wrapper {
    display: none;
    margin-bottom: 15px;
    @media screen and (max-width: 576px) {
        display: block;

        :deep(.menu-container) {
            margin: 0;
            display: flex;
            justify-content: space-between;

            .menu-item {
                width: auto;
            }
        }
    }
}

.home-tag {
    margin-bottom: 0 !important;
    padding-bottom: 10px;
    overflow: hidden;
}
</style>
